export const products = [
	{
		id: 1,
		title: 'Chatvez',
		description: `Chatvez is a conversational-bot with openAI API, made with React Js, Firebase, and Styled Components. It's an agent that simulates a Hugo Chávez parody.`,
		img: [require('./assets/images/chatvez.jpg')],
		link: 'chatvez.com',
		github: '',
	},
	{
		id: 2,
		title: 'Calculadora de Boletas',
		description: `This application made in Svelte calculates the value of the Boleta de Honorarios in Chile. It connects to the SII and copies the information generated.`,
		img: [require('./assets/images/boletas.jpg')],
		link: 'https://calculadoradeboletas.netlify.app/',
		github: '',
	},
	{
		id: 3,
		title: 'Sibodigital.cl',
		description: `It is a digital platform that connects to the internal tax system and, through AI, classifies invoices for later accounting.`,
		img: [require('./assets/images/sibodigital.png')],
		link: 'www.sibodital.cl',
		github: '',
	},
]
